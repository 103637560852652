// Core modules
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

// Application model and services
import { LoginModel } from '@shared/models/login.model';
import { ApiService } from './api.service';
import { CampaignModel } from '@app/shared/models/campaign.model';

export const TOKEN_NAME = 'kassa_admin_jwt_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private username = new BehaviorSubject('');
  private campaign = new BehaviorSubject(new CampaignModel());
  private loginSubject = new Subject();

  /**
   * Life cycle method
   * @param apiService instance of api service
   */
  constructor(private apiService: ApiService) {
  }

  /**
   * Function to login in to the application
   * @param loginData data to send in the request
   */
  login(loginData: LoginModel) {
    return this.apiService.post('/api/account/login', loginData);
  }

  register(payload) {
    return this.apiService.post('/api/account/register', payload);
  }

  forgotPassword(payload) {
    return this.apiService.post('/api/account/forgotpassword', payload);
  }

  resetPassword(payload) {
    return this.apiService.post('/api/account/resetpassword', payload);
  }

  confirmRegister(userId, code) {
    return this.apiService.get(`/api/account/confirmemail?userId=${userId}&code=${code}`);
  }

  /**
   * Method to logout from the system
   */
  logout() {
    localStorage.removeItem(TOKEN_NAME);
    this.setUsername('');
    return this.apiService.post('/api/account/logout',{});
  }

  /**
   * Method to check if user is authenticated or not
   */
  isAuthenticated() {
    if (localStorage.getItem(TOKEN_NAME) === undefined || localStorage.getItem(TOKEN_NAME) === null) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Set username after login
   * @param data username
   */
  setUsername(data): void {
    this.username.next(data);
  }

  /**
   * Return username to be display in header
   */
  getUsername(): Observable<any> {
    return this.username.asObservable();
  }

  setCampaign(campaign:CampaignModel): void {
    this.campaign.next(campaign);
  }

  getCampaign(): Observable<any> {
    return this.campaign.asObservable();
  }
  /**
   * Return user email address
   */
  getUserInfo(): Observable<any> {
    if (this.isAuthenticated()){
      return this.apiService.get('/api/account/userinfo');
    }
    return new Observable(observer => {
      observer.next(null);
      observer.complete();
    });
  }

  /**
   * Return user personal information
   */
  getUser(): Observable<any> {
    if (this.isAuthenticated()){
      return this.apiService.get('/api/user');
    }
    return new Observable(observer => {
      observer.next(null);
      observer.complete();
    });
  }

  /**
   * Save  user information
   */
  saveUser(payload): Observable<any> {
    return this.apiService.post('/api/user', payload);
  }

  /**
   * Set campagin data for opening login dialog
   * @param data data for campaign details
   */
  setLoginDialog(data) {
    this.loginSubject.next(data);
  }

  /**
   * Obsevable for showing login dialog on campagin detail page if user not logged in
   */
  getLoginDialog() {
    return this.loginSubject.asObservable();
  }
}
