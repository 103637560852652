// Core module
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

// Application validators and services
import { AuthService, TOKEN_NAME } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AgeValidator } from '@shared/validators/age-validator';
import { switchMap, tap } from 'rxjs/operators';

/**
 * SignUp component
 */
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {

  signUpForm: UntypedFormGroup;
  public showValidation: boolean = false;
  public emailKey: string = 'e';

  /**
   * Life cycle method
   * @param dialogRef instance of dialog
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private dialog: MatDialogRef<SignUpComponent>,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
    this.signUpForm = this.fb.group({
      email: new UntypedFormControl(this.route.snapshot.queryParams[this.emailKey], [Validators.required, Validators.email]),
      firstname: new UntypedFormControl('', [Validators.required]),
      lastname: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(100), Validators.pattern('(?=.{6,})(?=.*?[^\w\s])(?=.*?[0-9]).*?[a-zA-Z].*')]),
      dateOfBirth: new UntypedFormControl('', [Validators.required, AgeValidator]),
      optIn: new UntypedFormControl(false),
      lang: new UntypedFormControl(this.data.lang)
    }
      // , { validators: CustomValidator.matchPassword }
    );
  }

  // Returns email
  get email(): AbstractControl {
    return this.signUpForm.get('email');
  }

  // Returns firstname
  get firstname(): AbstractControl {
    return this.signUpForm.get('firstname');
  }

  // Returns lastname
  get lastname(): AbstractControl {
    return this.signUpForm.get('lastname');
  }

  // Return password
  get password(): AbstractControl {
    return this.signUpForm.get('password');
  }

  // Return confirm password
  get dateOfBirth(): AbstractControl {
    return this.signUpForm.get('dateOfBirth');
  }

  /**
   * Sign up
   */
   register(): void {
    this.showValidation = true;
    if (this.signUpForm.valid) {
      const payload = this.signUpForm.value;
      payload.dateOfBirth = new DatePipe('nl-NL').transform(this.dateOfBirth.value, 'MM-dd-yyyy');
      // payload.dateOfBirth = new Date(this.dateOfBirth.value).toUTCString();
      this.authService.register(payload).subscribe(
        () => {
          const loginData: any = {};
          loginData.username = this.signUpForm.value.email;
          loginData.password = this.signUpForm.value.password;
          loginData.grantType = 'password';
          this.authService.login(loginData).pipe(
            tap((result) => localStorage.setItem(TOKEN_NAME, result.access_token)),
            switchMap(() => this.authService.getUser()))
            .subscribe(
              (result) => {
                this.authService.setUsername(result.firstName ?? result.email);
                this.dialog.close();
                //this.snackBar.open(this.translate.instant('LOGGED_IN'), 'X', { duration: 3000 });
              },
              //() => this.snackBar.open(this.translate.instant('UNKNOWN_USER_PASSWORD'), 'X', { duration: 6000 })
            );
          this.snackBar.open(this.translate.instant('CONFIRMATION_EMAIL_SEND'), 'X', { duration: 3000 });
        },
        (response) => {
          //console.log(response);
          let message = this.translate.instant('ERROR_OCCURRED');
          if (response.error && response.error.error) {
            message = '';
            for (const model in response.error.error) {
              message = message + response.error.error[model] + ' ';
            }
            message.trim();
          }
          this.snackBar.open(message, 'X', { duration: 6000 });
        }
      );
    }
  }
}
