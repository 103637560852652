// Core modules
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Rxjs modules
import { Observable } from 'rxjs/internal/Observable';

// Constants
import { TOKEN_NAME } from '@core/services/auth.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  private token;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the user token from local storage
    this.token = localStorage.getItem(TOKEN_NAME);

    // If the token exists inject the token in the header
    if (this.token) {
      // Clone the existing request because you can't modify a request after creating it
      const cloned = req.clone({
        // Add the token header
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
          // 'Content-Type': 'application/json',
        },
      });

      // Return the cloned header instead of the original
      return next.handle(cloned);
    } else {
      // If there is not a token return the original request unmodified
      return next.handle(req);
    }
  }
}
