<div class="flex flex-row xs:flex-col justify-center items-center content-center">
  <div class="flex flex-col justify-center items-center content-center stepper" (click)="onClick()" appDragDrop (onFileDropped)="onFileSelected($event)">
    <img src="../../assets/images/beefund-receipt.png" class="logo"/>
    <span class="upload" translate>Upload Kassabon</span>
  </div>
  <div class="thumbnails flex flex-col justify-start items-center content-center margin-right-10">
    <div *ngFor="let file of files" class="flex flex-row justify-center items-center content-center">
      <div *ngIf="file.type!=='application/pdf'">
        <img [src]="file.src" [alt]='file.name'/>
      </div>
      <div *ngIf="file.type==='application/pdf'" class="pdf">
        <img src="../../assets/images/pdf.png" [alt]='file.name'/>
        <div class="filename">{{file.name}}</div>
      </div>
      <button mat-icon-button color="primary" (click)="removeFile(file)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
<input #fileUpload type="file" multiple class="input-file-upload-hidden" (change)="onFileSelected($event.dataTransfer?$event.dataTransfer.files:$event.target.files)" [accept]="accept">
<form [formGroup]="uploadForm">
  <mat-form-field class="input-file-upload-hidden" >
    <input formControlName='uploadFiles' matInput>
  </mat-form-field>
  <mat-error *ngIf="uploadFiles.touched && uploadFiles.invalid && uploadFiles.errors.required" translate>Selecteer kassabon</mat-error>
</form>