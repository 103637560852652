// Core modules
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

// Application components
import { AuthService } from '@core/services/auth.service';
import { RequestService } from '@core/services/request.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { LoginComponent } from '../login/login.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';
import { CookieService } from 'ngx-cookie-service';
import { CampaignModel } from '@app/shared/models/campaign.model';

/**
 * Header component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private countryOpen = false;
  private languageOpen = false;
  public campaign:CampaignModel;

  @ViewChild('countrySelect') countrySelect: MatSelect; 
  @ViewChild('languageSelect') languageSelect: MatSelect; 

  @HostListener('document:click', ['$event'])
  clickOut(event) {
      if (this.countryOpen) 
      {
        this.countrySelect.close();
      }
      if (this.languageOpen) 
      {
        this.languageSelect.close();
      }
   }
   
  public username: string;
  public email: string;
  languages = [];
  countries = [
    {
      value: 'NL',
      class: 'fi fi-nl',
      name: 'Netherlands'
    }, 
    {
      value: 'BE',
      class: 'fi fi-be',
      name: 'Belgium'
    }, 
    {
      value: 'FR',
      class: 'fi fi-fr',
      name: 'France'
    }, 
    {
      value: 'DE',
      class: 'fi fi-de',
      name: 'Germany'
    }
  ];
  selectedLanguage = 'nl';
  selectedCountry = 'NL'; 

  mySubscription;
  /**
   * Life cycle method
   * @param dialog MatDialog
   * @param authService AuthService
   * @param requestService RequestService
   * @param router Router
   * @param route ActivatedRoute
   * @param snackBar MatSnackBar
   */
  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private requestService: RequestService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document
    //private httpClient: HttpClient
  ) { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
         // Trick the Router into believing it's last link wasn't previously loaded
         this.router.navigated = false;
      }
    }); 
  }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
    this.getBrowserDetectCountry();
    //if (this.authService.isAuthenticated()) {
    this.authService.getUsername().subscribe((data) => {
      this.username = data ? data : ''
    });
    this.authService.getCampaign().subscribe((campaign)=>{
      this.campaign = campaign;
      if (this.campaign.countryId === 2 && !this.route.snapshot.queryParams.lang)
      {
        this.selectedCountry = 'BE';
        this.onCountryChange('BE');
      }
      //console.log('header campaign data',campaign);
    })
    //}
    this.updateAuthenticationHeader();
    this.authService.getLoginDialog().subscribe((data) => this.openLoginDialog(data));
    //this.onCountryChange(this.selectedCountry);
    //this.onLanguageChange(this.selectedLanguage);
    this.countries.map(c=>{
      this.translateService.get(c.name).subscribe((translation)=>{
        c.name = translation;
      })
    })
  }

  ngOnDestroy(){
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  selectedCountryObject()
  {
    return this.countries.find(c=>c.value == this.selectedCountry);
  }
  
  updateAuthenticationHeader() {
    if (this.authService.isAuthenticated()) {
      this.authService.getUser().subscribe(
        (data) => this.authService.setUsername(data.firstName ?? data.email),
        (error)=> this.authService.logout());
    }
  }

  /**
   * Open login dialog
   */
  openLoginDialog(campaignData?): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '568px',
      backdropClass: 'kbk-backdrop',
      data: { email: this.email},
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.email = data.email;
      if (data.event === 'signUp') {
        this.openSignUpDialog();
      } else if (data.event === 'forgotPassword') {
        this.openForgotPasswordDialog();
      } else if (data.event === 'login') {
        this.updateAuthenticationHeader();
      }
      // Handling for campaign details page
      if (campaignData && campaignData.campaignId) {
        this.authService.getUserInfo().subscribe((user) => {
          if (user!=null){ 
            this.requestService.checkDuplicateEmail(campaignData.campaignId, user.email).subscribe((result) => {
              if (!result) {
                this.router.navigate([`campaigns/${campaignData.urlPath}/${campaignData.campaignId}/upload`],
                  { queryParams: this.route.snapshot.queryParams });
              } else {
                this.snackBar.open(this.translateService.instant('DUPLICATE_USER_REQUEST'), 'X', { duration: 3000 });
              }
            });
          }
        });
      }
    });
  }

  /**
   * Open sign up dialog
   */
  openSignUpDialog(): void {
    const dialogRef = this.dialog.open(SignUpComponent, {
      width: '568px',
      backdropClass: 'kbk-backdrop',
      data: { email: this.email, lang: this.selectedLanguage + '-' + this.selectedCountry },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'login') {
        this.openLoginDialog();
      }
    });
  }

  openForgotPasswordDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: '568px',
      backdropClass: 'kbk-backdrop',
      data: { email: this.email, lang: this.selectedLanguage + '-' + this.selectedCountry },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'signUp') {
        this.openSignUpDialog();
      }
    });
  }

  /**
   * Logged out the user and navigate to home
   */
  logout(): void {
    this.authService.logout();
    this.router.navigate(['']);
  }

  reload()
  {
    let lang = this.selectedLanguage+'-'+this.selectedCountry;
    this.cookieService.set('lang', lang);
    //this.router.navigate(['campaigns', lang]);
    console.log('test');
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        lang: lang
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      //skipLocationChange: true
      // do not trigger navigation
    });
  }

  countryOpened(event)
  {
    this.countryOpen = event;
  }

  onCountryChange(selectedCountry: string) {
    this.countryOpen = false;
    if (selectedCountry === 'NL') {
      this.languages = [
        {
          value: 'nl',
          name: 'nl'
        }
      ]
      this.selectedLanguage = 'nl';
    } else if (selectedCountry === 'BE') {
      this.languages = [
        {
          value: 'fr',
          name: 'fr'
        }, 
        {
          value: 'nl',
          name: 'nl'
        }
      ]
      this.selectedLanguage = 'nl';
    } else if (selectedCountry === 'FR') {
      this.languages = [
        {
          value: 'fr',
          name: 'fr'
        }
      ]
      this.selectedLanguage = 'fr';
    } else if (selectedCountry === 'DE') {
      this.languages = [
        {
          value: 'de',
          name: 'de'
        }
      ]
      this.selectedLanguage = 'de';
    }
    this.translateService.setDefaultLang(this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
    this.document.documentElement.lang = this.selectedLanguage; 
    this.reload();
  }

  languageOpened(event)
  {
    this.languageOpen = event;
  }


  onLanguageChange(selectedLanguage: string) {
    this.selectedLanguage = selectedLanguage;
    this.translateService.setDefaultLang(this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
    this.reload();
  }

  getBrowserDetectCountry(): void {
    // var requestUrl = "http://ip-api.com/json";
    // this.httpClient.get(requestUrl).subscribe((result: any) => {
    //   const country = this.countries.find(c => c.name?.toLowerCase().includes(result.country?.toLowerCase()));
    //   if (country) {
    //     this.selectedCountry = country.value;
    //   }
    // });
    let lang = navigator.language;
    if (this.cookieService.check('lang'))
    {
      lang = this.cookieService.get('lang');
    }
    if(this.route.snapshot.queryParams.lang){
      lang = this.route.snapshot.queryParams.lang;
    }
    
    if (lang == 'nl-NL')
    {
      this.selectedCountry = 'NL';
      this.selectedLanguage = 'nl';
    }
    else if (lang == 'nl-BE')
    {
      this.selectedCountry = 'BE';
      this.selectedLanguage = 'nl';
    }
    else if (lang == 'fr-BE')
    {
      this.selectedCountry = 'BE';
      this.selectedLanguage = 'fr';
    }
    else if (lang == 'fr-FR')
    {
      this.selectedCountry = 'FR';
      this.selectedLanguage = 'fr';
    }
    else if (lang == 'de-DE')
    {
      this.selectedCountry = 'DE';
      this.selectedLanguage = 'de';
    }
    this.translateService.setDefaultLang(this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
    this.document.documentElement.lang = this.selectedLanguage; 
  }
}
