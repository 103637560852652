import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { CampaignService } from '../services/campaign.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(private campaignService: CampaignService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const urlPaths = state.url.split('?');
      const urlPath = urlPaths[0].substr(1);

      return this.campaignService.getCampaignByUrlPath(urlPath).pipe(catchError(() => {

        return of(true);
      }
      )).pipe(map((campaign) => {
          if (campaign && urlPath !== 'error') {
            return this.router.createUrlTree(['campaigns', urlPath, campaign.campaignId ], { queryParams: state.root.queryParams });
          }

          return true;
        }
      )
    );
  }
}
