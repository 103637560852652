// Core  modules
import { Component, OnInit } from '@angular/core';


// Application services

export let baseUrl: string = 'https://cashbackswebapicore.whitedune-47840e2f.westeurope.azurecontainerapps.io';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'BeeFund';

  /**
   * Life cycle method
   * @param configService RuntimeConfigLoaderService
   * @param doc any
   * @param router Router
   */
  constructor(){
  }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
  }
}
