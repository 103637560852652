<div class="container mx-auto text-center">
  <span class="close"  mat-dialog-close><mat-icon>close</mat-icon></span>
  <div class="justify-center items-center text-center">
    <img src="../../../../assets/images/logo-img.svg" alt="logo" class="mx-auto">
    <h1 mat-dialog-title translate>Registreren</h1>
    <p class="text-center" translate>Maak een account aan om gebruik te kunnen maken van onze acties.</p>
  </div>
  <form [formGroup]="signUpForm" autocomplete="on">
    <div class="flex flex-col">
      <div class="flex flex-row md:flex-col mr-2">
        <div class="basis-4/5">
          <mat-form-field appearance="fill" floatLabel="auto">
            <mat-label translate>E-mail</mat-label>
            <input matInput [placeholder]="'E-mail'|translate" formControlName="email" id="email" autocomplete="email">
            <mat-error>
              <div *ngIf="email.invalid && email.errors.required" translate>E-mail is verplicht</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="basis-2/5">
          <mat-form-field appearance="fill" floatLabel="auto">
            <mat-label translate>Voornaam</mat-label>
            <input matInput [placeholder]="'Voornaam'|translate" formControlName="firstname" id="firstname" autocomplete="given-name">
            <mat-error>
              <div *ngIf="firstname.invalid && firstname.errors.required" translate>Voornaam is verplicht</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="basis-2/5">
          <mat-form-field appearance="fill" floatLabel="auto">
            <mat-label translate>Achternaam</mat-label>
            <input matInput [placeholder]="'Achternaam'|translate" formControlName="lastname" id="lastname" autocomplete="family-name">
            <mat-error>
              <div *ngIf="lastname.invalid && lastname.errors.required" translate>Achternaam is verplicht</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="basis-4/5">
          <mat-form-field appearance="fill" floatLabel="auto">
          <mat-label translate>Wachtwoord</mat-label>
          <input matInput type="password" [placeholder]="'Wachtwoord'|translate" formControlName="password" id="password" autocomplete="new-password">
          <mat-error>
            <div *ngIf="password.invalid && password.errors.required" translate>Wachtwoord is verplicht</div>
            <div *ngIf="password.invalid && !password.errors.required && password.errors.minlength" translate>Wachtwoord moet minimaal 6 karakters bevatten</div>
            <div *ngIf="password.invalid && !password.errors.required && !password.errors.minlength && password.errors.pattern" translate>Wachtwoord moet een letter en getal bevatten</div>
          </mat-error>
          </mat-form-field>
        </div>
        <p translate>
          Je moet minimaal 16 jaar zijn om je aan te melden. Andere mensen die BeeFund gebruiken, kunnen je geboortedatum niet zien.
        </p>
        <mat-form-field appearance="fill" floatLabel="auto">
          <mat-label translate>Geboortedatum</mat-label>
          <input matInput type="date" [placeholder]="'Geboortedatum'|translate" formControlName="dateOfBirth" id="dateOfBirth" autocomplete="bday">
          <mat-error>
            <div *ngIf="dateOfBirth.invalid && dateOfBirth.errors.required" translate>Geboortedatum is verplicht</div>
            <div *ngIf="dateOfBirth.invalid && !dateOfBirth.errors.required && dateOfBirth.errors.invalidAge" translate>Ongeldige datum</div>
            <div *ngIf="dateOfBirth.invalid && !dateOfBirth.errors.required && dateOfBirth.errors.age" translate>Je moet minimaal 16 zijn</div>
          </mat-error>
        </mat-form-field>
        <mat-checkbox id="optIn" color="primary" formControlName="optIn" translate>Houd mij op de hoogte van nieuwe aanbiedingen!</mat-checkbox>
      </div>
      <div class="flex flex-col justify-center items-center content-center" class="margin-right-10">
        <p><span translate>Door het aanmaken van een BeeFund account ga je akkoord met ons </span> <a class="privacy" href="/privacy" target="_blank" translate>privacybeleid</a>.</p>
        <button mat-raised-button (click)="register()" type="submit" color="accent" [disabled]="signUpForm.invalid">{{'Registrere'|translate}}</button>
        <p><span translate>Al een account? </span> <a mat-dialog-close="login" translate> Inloggen</a></p>
      </div>
    </div>
  </form>
</div>