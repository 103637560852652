// Core Component
import { Component, OnInit } from '@angular/core';

// Event list models and services
import { ActivityService } from '@core/services/activity.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  public showLoader = false;

  /**
   * Life cycle method
   * @param activityService ActivityService
   */
  constructor(private activityService: ActivityService) { }

  /**
   * Angular Life cycle method
   */
  ngOnInit() {
    this.activityService.changeLoaderStatus.subscribe((response) => {
      this.showLoader = Boolean(response);
    });
  }
}
