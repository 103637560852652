/**
 * Campaign model
 */
export class CampaignModel {
  campaignId: number;
  backgroundImageId: number;
  brandName: string;
  companyName: string;
  companyCity: string;
  contactEmail: string;
  contactUrl: string;
  description: string;
  discountPrice: number;
  done: boolean;
  endDate: string;
  homepageImageId: number;
  isActive: boolean;
  logoImageId: number;
  minimumProducts: number;
  maximumProducts: number;
  multipleProducts: boolean;
  price: number;
  private: boolean;
  name: string;
  startDate: string;
  stylePath: string;
  termsDescription: string;
  urlPath: string;
  useCode: boolean;
  campaignProducts?: ICampaignProducts[];
  amountType:string;
  languageId: number;
  countryId: number;
  languages: number[];
  minimumAge: number;
  external: boolean;
}

export interface ICampaignProducts {
  campaignProductId: number;
  campaignId: number;
  productName: string;
  productImageId: number;
  price: number;
  discountTypeId: number;
  discountPrice: number;
  discount: number;
  checked?: boolean;
}
