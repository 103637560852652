// Core  modules
import { Component, OnInit } from '@angular/core';

// Third party services
//import { GoogleTagManagerService } from 'angular-google-tag-manager';

// Application services

export let baseUrl: string = 'https://cashbackswebapicore.azurewebsites.net';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'BeeFund';

  /**
   * Life cycle method
   * @param configService RuntimeConfigLoaderService
   * @param gtmService GoogleTagManagerService
   * @param doc any
   * @param router Router
   */
  constructor(){
  }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
  }
}
