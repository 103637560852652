import { AbstractControl } from '@angular/forms';

export function AgeValidator(control: AbstractControl) {
  const age = 16;
  if (!control.value) {
    return null;
  }
  const dateParts = control.value.split('-');
  const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
  const today = new Date();
  const minAge = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());
  const maxAge = new Date(today.getFullYear() - 120, today.getMonth(), today.getDate());
  if (maxAge > date || dateParts[0] < 1900) {
    return { invalidAge: true };
  }
  if (minAge < date) {
    return { age: true };
  }

  return null;
}
