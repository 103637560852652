import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ActivityService } from '../services/activity.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    constructor(public activityService: ActivityService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.activityService.showLoader();

        return next.handle(req).pipe(
            finalize(() => this.activityService.hideLoader())
        );
    }
}
