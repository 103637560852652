// Core module
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

// Application services
import { AuthService } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

/**
 *  Login Component
 */
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: UntypedFormGroup;
  public language = 'nl';

  /**
   * Life cycle method
   * @param dialogRef instance of dialog
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) { }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(100), Validators.pattern('(?=.{6,})(?=.*?[^\w\s])(?=.*?[0-9]).*?[a-zA-Z].*')]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
      userId: new UntypedFormControl(''),
      token: new UntypedFormControl(''),
    });

    this.route.queryParams.subscribe((params) => {
      this.userId.setValue(params.userId);
      this.token.setValue(params.token);
    });

  }

  // User id of the user
  get userId(): AbstractControl {
    return this.changePasswordForm.get('userId');
  }

  // Token of the user
  get token(): AbstractControl {
    return this.changePasswordForm.get('token');
  }

  // Returns password
  get password(): AbstractControl {
    return this.changePasswordForm.get('password');
  }

  // Returns confirm password
  get confirmPassword(): AbstractControl {
    return this.changePasswordForm.get('confirmPassword');
  }

  /**
   * Change password
   */
  changePassword(): void {
    if (this.changePasswordForm.valid) {
      this.authService.resetPassword(this.changePasswordForm.value).subscribe(
        (result) => {
          this.snackBar.open(this.translate.instant('PASSWORD_CHANGED'), 'X', { duration: 3000 });
          this.router.navigate(['/']);
        },
        (response) => {
          let message = this.translate.instant('ERROR_OCCURRED');
          if (response.error && response.error.modelState) {
            message = '';
            for (const model in response.error.modelState) {
              message = message + response.error.modelState[model] + ' ';
            }
            message.trim();
          }
          this.snackBar.open(message, 'X', { duration: 6000 });
        }
      );
    }
  }

  close(): void {
    this.router.navigate([`/`]);
  }
}
