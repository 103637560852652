// Core modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Application components
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from '../app-material.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ConfirmRegistrationComponent } from './components/confirm-registration/confirm-registration.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { DragDropDirective } from './directives/drag-drop.directive';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        SignUpComponent,
        ForgotPasswordComponent,
        FileUploadComponent,
        ChangePasswordComponent,
        ConfirmRegistrationComponent,
        LoaderComponent,
        DragDropDirective,
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        AppMaterialModule,
        HeaderComponent,
        FooterComponent,
        TranslateModule,
        FileUploadComponent,
        LoaderComponent,
    ]
})
export class SharedModule { }
