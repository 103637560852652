<div class="flex flex-row container justify-center items-start content-start">
  <div class="basis-2/5 wrapper">
    <span class="close" (click)="close()"><mat-icon>close</mat-icon></span>
    <div class="justify-center items-center text-center">
      <img src="../../../../assets/images/logo-img.svg" class="mx-auto"/>
      <h1 mat-dialog-title translate>Wijzig wachtwoord</h1>
      <p translate>Voer een nieuw wachtwoord in</p>
    </div>
    <form [formGroup]="changePasswordForm">
      <div class="flex flex-col">
        <mat-form-field appearance="fill" floatLabel="auto">
          <mat-label translate>Wachtwoord</mat-label>
          <input type="password" matInput [placeholder]="'Wachtwoord'|translate" formControlName="password" id="password" autocomplete="new-password">
          <mat-error>
            <div *ngIf="password.invalid && password.errors.required" translate>Voer een wachtwoord in.</div>
            <div *ngIf="password.invalid && !password.errors.required && password.errors.minlength" translate>Wachtwoord moet minimaal 6 karakters bevatten</div>
            <div *ngIf="password.invalid && !password.errors.required && !password.errors.minlength && password.errors.pattern" translate>Wachtwoord moet een letter en getal bevatten</div>
              </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" floatLabel="auto">
          <mat-label translate>Bevestig wachtwoord</mat-label>
          <input type="password" matInput [placeholder]="'Bevestig wachtwoord'|translate" formControlName="confirmPassword" id="confirmPassword" autocomplete="new-password">
          <mat-error>
            <div *ngIf="confirmPassword.invalid && confirmPassword.errors.required" translate>Voer wachtwoord bevestiging in</div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-col justify-center items-center content-center" class="mr-2">
        <button mat-raised-button (click)="changePassword()" type="submit" color="accent">{{'Verander wachtwoord'|translate}}</button>
      </div>
    </form>
  </div>
</div>
