// Core modules
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Third party modules
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Error interceptor
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  /**
   * Life cycle method
   * @param authenticationService AuthService
   */
  constructor(
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        let error = 'version:1.0.1:' + err.error.message || err.statusText;
        if (err.error && err.error.stackTrace)
        {
          error = error + ' : '  + err.error.stackTrace;
        }

        return throwError(err);
      }));
  }
}
