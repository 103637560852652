<form [formGroup]="confirmForm">
  <div class="flex flex-row container justify-center items-center content-center text-center">
    <div class="basis-2/5 wrapper">
      <div class="justify-center items-center text-center">
        <img src="../../../../assets/images/logo-img.svg" class="mx-auto"/>
        <h1 mat-dialog-title translate>Registratie voltooid</h1>
        <p translate>Bedankt voor het bevestigen van jouw registratie.</p>
      </div>
      <div class="flex flex-col justify-center items-center content-center" class="margin-right-10">
        <button mat-raised-button (click)="confirmRegister()" color="accent" routerLink="/">{{'Naar de acties'|translate}}</button>
      </div>
    </div>
  </div>
</form>
