// Core modules
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

/**
 * File upload component
 */
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {

  @Input() multiple;
  @Input() accept;
  @Input() maxFileSize;
  @Input() invalidFileSizeMessageSummary;
  @Input() invalidFileTypeMessageSummary;

  @Output() valueChange = new EventEmitter();

  @ViewChild('fileUpload')
  fileUpload: ElementRef;
  public files = [];
  uploadForm;

  /**
   * Life cycle method
   * @param sanitizer DomSanitizer
   * @param snackBar MatSnackBar
   */
  constructor(
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private translate: TranslateService
  ) {}

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
    this.uploadForm = this.fb.group({
      uploadFiles: new UntypedFormControl('', [Validators.required]),
    });
  }

  /**
   * Return upload files control
   */
  get uploadFiles(): AbstractControl {
    return this.uploadForm.get('uploadFiles');
  }

  /**
   * Click event
   */
  onClick() {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.click();
    }
  }

  /**
   * When file selected to upload
   * @param event Event
   */
  onFileSelected(files) {
    for (const file of files) {
      if (this.validate(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)));
        if (!this.isMultiple()) {
          this.files = [];
        }
        const reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url
        reader.onload = (event1: any) => file.src = event1.target.result;
        this.files.push(file);
        this.uploadFiles.setValue(this.files);
        this.valueChange.emit(this.files);
      }
    }
  }

  /**
   * Remove uploaded file
   * @param file File
   */
  removeFile(file) {
    const ix = this.files.indexOf(file);
    if (this.files && -1 !== ix) {
      this.files.splice(ix, 1);
      this.clearInputElement();
    }
  }

  /**
   * Validate file size and file type
   * @param file File
   */
  validate(file: File) {
    let valid = true;
    if (this.accept.indexOf(file.type.split('/')[0]) <= -1) {
      this.snackBar.open(this.invalidFileTypeMessageSummary, 'X', { duration: 6000 });
      valid = false;
    }
    if (file.size > this.maxFileSize * 1024 * 1024) {
      this.snackBar.open(this.invalidFileSizeMessageSummary, 'X', { duration: 6000 });
      valid = false;
    }

    return valid;
  }

  /**
   * Clear file input value
   */
  clearInputElement() {
    this.fileUpload.nativeElement.value = '';
  }

  /**
   * Return if multiple files allowed or not
   */
  isMultiple(): boolean {
    return this.multiple;
  }

}
