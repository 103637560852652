<mat-toolbar class="white-bg" *ngIf="campaign && campaign.private">
  <mat-toolbar-row>
    <img src="../../assets/images/logo.png" class="logo" alt="header logo">
    <span class="spacer"></span>
    <div class="languages basis-12" *ngIf="campaign.countryId == 2">
      <a (click)="onLanguageChange('nl')">NL</a>|<a (click)="onLanguageChange('fr')">FR</a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar class="white-bg" *ngIf="!campaign || !campaign.private">
  <mat-toolbar-row>
    <a [routerLink]="''"><img src="../../assets/images/logo.png" class="logo" alt="header logo"></a>
    <span class="spacer"></span>
    <div class="block sm:hidden">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngIf="username">
          <a class="margin-right-10" translate routerLink='/edit-profile'>{{"Welkom"|translate}} {{username}}</a>
        </button>
        <button mat-menu-item *ngIf="username">
          <a (click)="logout()" translate>Uitloggen</a>
        </button>
        <button mat-menu-item *ngIf="!username">
          <a (click)="openLoginDialog()" class="margin-right-10" translate>Inloggen</a>
        </button>
        <button mat-menu-item *ngIf="!username" >
          <a (click)="openSignUpDialog()" translate>Registreren</a>
        </button>
      </mat-menu>
    </div>
    <div class="hidden sm:block">
      <a *ngIf="username" class="welcome margin-right-10" routerLink='/edit-profile'>{{'Welkom'|translate}} {{username}}</a>
      <a *ngIf="username" (click)="logout()" translate>Uitloggen</a>
      <a (click)="openLoginDialog()" *ngIf="!username" class="margin-right-10" translate>Inloggen</a>
      <button *ngIf="!username" mat-raised-button class="button" color="warn" (click)="openSignUpDialog()">{{'Registreren'|translate}}</button>
    </div>
    <div class="basis-12 select">
      <mat-select #countrySelect='matSelect' (openedChange)="countryOpened($event)" [(ngModel)]="selectedCountry" (selectionChange)="onCountryChange($event.value)">
        <mat-select-trigger>
          <span [class]="selectedCountryObject().class"></span>
          <!-- {{selectedCountryObject().name}} -->
        </mat-select-trigger>
        <mat-option *ngFor="let country of countries" [value]="country.value">
          <span [class]="country.class"></span>
          <!-- {{ country.name }} -->
        </mat-option>
      </mat-select>
    </div>
    <div class="basis-12 select" *ngIf="languages.length>1">
      <mat-select  #languageSelect='matSelect' (openedChange)="languageOpened($event)" [(ngModel)]="selectedLanguage" (selectionChange)="onLanguageChange($event.value)">
        <mat-option *ngFor="let language of languages" [value]="language.value">
          {{ language.name }}
        </mat-option>
      </mat-select>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
