// Core module
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// Application services
import { AuthService } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

/**
 *  Login Component
 */
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: UntypedFormGroup;
  public language = 'nl';

  /**
   * Life cycle method
   * @param dialogRef instance of dialog
   */
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private dialog: MatDialogRef<ForgotPasswordComponent>,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: new UntypedFormControl(this.data.email, [Validators.required]),
      lang: new UntypedFormControl(this.data.lang)
    });
  }

  // Returns username
  get email(): AbstractControl {
    return this.forgotPasswordForm.get('email');
  }

  /**
   * Login into the application
   */
  forgotPassword(): void {
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(
        (result) => {
          this.dialog.close('login');
          this.snackBar.open(this.translate.instant('RESETLINK_EMAIL_SEND'), 'X', {duration: 3000});
        },
        () => this.snackBar.open(this.translate.instant('ERROR_OCCURRED'), 'X', {duration: 6000})
      );
    }
  }
}
