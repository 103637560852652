// Core modules
import { Injectable } from '@angular/core';

// Third party modules
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {

  /**
   * Life cycle method
   * @param httpClient HttpClient
   */
  constructor(
    private apiService: ApiService
  ) { }

  /**
   * Return campaign list
   */
  getCampaigns(lang): Observable<any> {
    //console.log('getting data for', lang);
    return this.apiService.get(`/api/campaignsbylang?lang=${lang}&includeExternal=true`);
  }

  /**
   * Return campaign by id
   */
  getCampaignById(id: number, languageId: string): Observable<any> {
    if (languageId)
    {
      return this.apiService.get(`/api/campaigns/${id}?languageId=${languageId}`);
    }
    return this.apiService.get(`/api/campaigns/${id}`);
  }

  /**
   * Return campaign by url path
   */
  getCampaignByUrlPath(urlPath: string): Observable<any> {
    return this.apiService.get(`/api/campaigns/campaignbyurlpath/${urlPath}`);
  }
}
