// Core module
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

// Application services
import { AuthService, TOKEN_NAME } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, tap } from 'rxjs/operators';

/**
 *  Login Component
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public showValidation: boolean = false;
  public emailKey: string = 'e';

  /**
   * Life cycle method
   * @param dialogRef instance of dialog
   */
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialog: MatDialogRef<LoginComponent>,
    private snackBar: MatSnackBar,
    public route: ActivatedRoute,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  /**
   * Life cycle init method
   */
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: new FormControl(this.route.snapshot.queryParams[this.emailKey], [Validators.required]),
      password: new FormControl('', [Validators.required]),
      grantType: new FormControl('password'),
    });
  }

  // Returns username
  get username(): AbstractControl {
    return this.loginForm.get('username');
  }

  // Returns password
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  /**
   * Login into the application
   */
  login(): void {
    this.showValidation = true;
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).pipe(
        tap((result) => localStorage.setItem(TOKEN_NAME, result.access_token)),
        switchMap(() => this.authService.getUser()))
        .subscribe(
          (result) => {
            this.authService.setUsername(result.firstName ?? result.email);
            this.dialog.close('login');
            this.snackBar.open(this.translate.instant('LOGGED_IN'), 'X', { duration: 3000 });
          },
          (error) => {
            //console.log(error);
            let message = this.translate.instant('UNKNOWN_USER_PASSWORD');
            if (error.statusText=='Unknown Error')
            {
              message = this.translate.instant('ERROR_OCCURRED');
              this.snackBar.open(message, 'X', { duration: 6000 })
              throw error;
            }
            this.snackBar.open(message, 'X', { duration: 6000 })
          }
        );
    }
  }

  /**
   * Open forgot password dialog
   */
  forgotPassword() {
    this.dialog.close({ event: 'forgotPassword', email: this.username.value });
  }

  /**
   * Open sign up dialog
   */
  signUp() {
    this.dialog.close({ event: 'signUp', email: this.username.value });
  }
}
