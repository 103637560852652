import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RequestService {

  constructor(private apiService: ApiService, private authService: AuthService) { }

  saveRequest(request) {
    if (this.authService.isAuthenticated()){
      return this.apiService.post('/api/request', request);
    }
    else {
      return this.apiService.post('/api/request/noauth', request);   
    }
  }

  checkDuplicateIban(campaignId: number, iban: string) {
    return this.apiService.get(`/api/request/duplicateiban?campaignId=${campaignId}&iban=${iban}`);
  }

  checkDuplicateEmail(campaignId: number, email: string) {
    return this.apiService.get(`/api/request/duplicateemail?campaignId=${campaignId}&email=${email}`);
  }

  checkActionCode(campaignId: number, code: string) {
    return this.apiService.get(`/api/request/checkcode?campaignId=${campaignId}&code=${code}`);
  }

  checkDuplicateCode(campaignId: number, code: string) {
    return this.apiService.get(`/api/request/duplicatecode?campaignId=${campaignId}&code=${code}`);
  }
  
  saveSubscriber(email: string, lang: string)
  {
    return this.apiService.post(`/api/request/subscribe?email=${email}&lang=${lang}`,{email: email, lang: lang});
  }
}
