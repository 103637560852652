<div class="container mx-auto text-center">
  <span class="close"  mat-dialog-close><mat-icon>close</mat-icon></span>
  <div class="justify-center items-center text-center">
    <img src="../../../../assets/images/logo-img.svg" class="mx-auto"/>
    <h1 mat-dialog-title translate>Wachtwoord vergeten</h1>
    <p translate>Voer het e-mailadres van je account in en we e-mailen je een link om het wachtwoord opnieuw in te stellen.</p>
  </div>
  <form [formGroup]="forgotPasswordForm">
    <div class="flex flex-col">
      <mat-form-field appearance="fill" floatLabel="auto">
        <mat-label translate>E-mail</mat-label>
        <input matInput [placeholder]="'E-mail'|translate" formControlName="email" id="email">
        <mat-error>
          <div *ngIf="email.invalid && email.errors.required" translate>E-mail is verplicht</div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex flex-col justify-center items-center content-center" class="margin-right-10">
      <!-- <span><a href="">Wachtwoord vergeten?</a></span> -->
      <button mat-raised-button (click)="forgotPassword()" type="submit" color="accent">{{'Resetlink sturen'|translate}}</button>
      <p><span translate>Nog niet geregistreerd?</span> <a mat-dialog-close='signUp' translate>Registreren</a></p>
    </div>
  </form>
</div>