// Core components
import { Injectable } from '@angular/core';

// Rxjs components
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  getLanguageCode(languageId:number) {
    switch (languageId)
    {        
        case 1: return 'nl'
        case 2: return 'en'
        case 3: return 'fr'
        case 4: return 'de'
        default: return 'nl'
    }
  }

  getLanguageId(languageCode:string) {
    switch (languageCode)
    {        
        case 'nl': return '1'
        case 'en': return '2'
        case 'fr': return '3'
        case 'de': return '4'
        default: return '1'
    }
  }

  getLanguageName(languageId:number) {
    switch (languageId)
    {        
        case 1: return 'Dutch'
        case 2: return 'English'
        case 3: return 'French'
        case 4: return 'German'
        default: return 'Dutch'
    }
  }
}
