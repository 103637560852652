// Core modules
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

// Application services
import { AuthService } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Confirm registration component
 */
@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss'],
})
export class ConfirmRegistrationComponent implements OnInit {

  confirmForm: UntypedFormGroup;

  /**
   * Life cycle method
   * @param route ActivatedRoute
   * @param fb FormBuilder
   * @param authService AuthService
   * @param snackBar MatSnackBar
   * @param router Router
   */
  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translate: TranslateService
  ) { }

  /**
   * Life cycle init method
   */
  ngOnInit() {
    this.confirmForm = this.fb.group({
      userId: new UntypedFormControl(''),
      code: new UntypedFormControl(''),
    });
    this.route.queryParams.subscribe((params) => {
      this.confirmForm.get('userId').setValue(params.userId);
      this.confirmForm.get('code').setValue(params.code);
      this.confirmRegister();
    });
  }

  /**
   * Change password
   */
  confirmRegister(): void {
    if (this.confirmForm.valid) {
      this.authService.confirmRegister(this.confirmForm.get('userId').value,
      encodeURIComponent(this.confirmForm.get('code').value)).subscribe(
          (result) => {
            this.snackBar.open(this.translate.instant('EMAIL_CONFIRMED'), 'X', { duration: 3000 });
            this.router.navigate(['/']);
          },
          (response) => {
            let message = this.translate.instant('ERROR_OCCURRED');
            if (response.error && response.error.modelState) {
              message = '';
              for (const model in response.error.modelState) {
                message = message + response.error.modelState[model] + ' ';
              }
              message.trim();
            }
            this.snackBar.open(message, 'X', { duration: 6000 });
          }
        );
    }
  }

}
