<footer class="py-4">
  <div class="flex justify-center items-center">
    <div class="mr-6">
      <a target="_blank" href="https://www.facebook.com/BeeFundCashback" class="icon facebook"></a>
    </div>
    <div class="mr-6">
      <a target="_blank" href="https://www.instagram.com/BeeFundCashback/" class="icon instagram"></a>
    </div>
  </div>
  <div class="flex justify-center items-center">
    <p>© BeeFund - <a href="/privacy" target="_blank" translate>privacybeleid</a></p>
  </div>
</footer>
