// Core components
import { Injectable } from '@angular/core';

// Rxjs components
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private loaderStatusSubject = new Subject();
  public changeLoaderStatus = this.loaderStatusSubject.asObservable();

  /**
   *  show loader
   */
  showLoader() {
    this.loaderStatusSubject.next(true);
  }

  /**
   *  hide loader
   */
  hideLoader() {
    this.loaderStatusSubject.next(false);
  }
}
