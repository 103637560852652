<div class="container mx-auto text-center">
  <span class="close"  mat-dialog-close><mat-icon>close</mat-icon></span>
  <div class="justify-center items-center text-center">
    <img class="mx-auto" src="../../../../assets/images/logo-img.svg" />
    <h1 mat-dialog-title translate>Inloggen</h1>
    <p translate>Log in met je BeeFund account</p>
  </div>
  <form [formGroup]="loginForm">
    <div class="flex flex-col">
      <div class="flex flex-row md:flex-col mr-2">
        <div class="basis-4/5">
          <mat-form-field appearance="fill" floatLabel="auto">
            <mat-label translate>E-mail</mat-label>
            <input matInput [placeholder]="'E-mail'|translate" formControlName="username" id="username" autocomplete="email">
            <mat-error>
              <div *ngIf="showValidation && username.invalid && username.errors.required" translate>E-mail is verplicht</div>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" floatLabel="auto">
            <mat-label translate>Wachtwoord</mat-label>
            <input matInput type="password" [placeholder]="'Wachtwoord'|translate" formControlName="password" id="password" autocomplete="current-password">
            <mat-error>
              <div *ngIf="showValidation && password.invalid && password.errors.required" translate>Wachtwoord is verplicht</div>
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field appearance="fill" floatLabel="auto">
            <mat-select [(value)]="language">
              <mat-option value="en">English</mat-option>
              <mat-option value="nl">Dutch</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
    </div>
    <div class="flex flex-col justify-center items-center content-center" class="margin-right-10">
      <p><a (click)="forgotPassword()" translate>Wachtwoord vergeten?</a></p>
      <button mat-raised-button (click)="login()" type="submit" color="accent">{{'Inloggen'|translate}}</button>
      <p><span translate>Nog niet geregistreerd? </span><a (click)="signUp()" translate> Registreren</a></p>
    </div>
  </div>
  </form>
</div>